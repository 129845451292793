import React from "react";
import Link from "next/link";
import { sendMetrics } from "../../utils/misc";
import styles from './sideMenu.module.scss';

const LinkWithMetric = ({
  isSsr = true,
  link,
  text,
  metric = null,
  isBlank = false,
  isRel = false,
  isHelp = false
}) => {
  if (!isSsr)
    return (
      <a
        href={link}
        target={isBlank ? "__blank" : ""}
        rel={isRel ? "noreferrer external" : ""}
        className={isHelp && styles.HelpLink}
      >
        {
          isHelp && <img src="/static/images/heart.svg" alt="heart" />
        }
        <span>{text}</span>
      </a>
    );
  else
    return (
      <Link href={link}>
        <a onClick={() => sendMetrics(metric)} className={isHelp && styles.HelpLink}>
          {
            isHelp && <img src="/static/images/heart.svg" alt="heart" />
          }
          <span>{text}</span>
        </a>
      </Link>
    );
};

export const LinksWithMetric = () => {
  return (
    <>
      <LinkWithMetric
        metric={"to_bonuses_from_header"}
        link={"/bonus/"}
        text={"Бонусы донорам"}
      />
      <LinkWithMetric text={"Топ доноров"} link={"/top/"} />
      <LinkWithMetric
        isRel
        text={"Журнал о донорстве"}
        isBlank
        link={
          "https://journal.donorsearch.org/?utm_source=header&utm_medium=ds&utm_campaign=donor_search"
        }
        isSsr={false}
      />
      <LinkWithMetric
        text={"Благотворительный магазин"}
        link={"/charityshop"}
        isSsr={false}
      />
      <LinkWithMetric
        text={"Как стать донором?"}
        metric={"to_how_from_header"}
        link={"/how/"}
      />
      <LinkWithMetric
        text={"Сборы на проект"}
        isSsr={false}
        link={
          "/fundraising"
        }
      />
      <LinkWithMetric
        text={"Найти донора"}
        metric={"to_helpme_from_header"}
        link={"/helpme/"}
      />
      <LinkWithMetric
        text={"Мероприятия"}
        isSsr={false}
        isBlank
        link={"https://unity.donorsearch.org/"}
      />
      <LinkWithMetric
        text={"Помочь проекту"}
        isSsr={false}
        link={
          "/donate/?utm_source=navbar&utm_medium=ds&utm_campaign=donor_search"
        }
        isHelp
      />
    </>
  );
};
