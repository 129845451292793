export const EXCEPTION_PAGES = ['donate', 'login', 'pre_sign_up', 'sign_up_email', 'sign_up'];

export const isExceptionPage = (pathname: string): boolean => {
  const cleanedPathname = pathname.split('?')[0];

  if (cleanedPathname.startsWith('/profile')) {
    return true;
  }

  if (/^\/fundraising\/[^\/]+\/?$/.test(cleanedPathname)) {
    return true;
  }

  const finalPathname = cleanedPathname.replace(/^\/+|\/+$/g, '');

  if (EXCEPTION_PAGES.includes(finalPathname)) {
    return true;
  }

  return false;
};