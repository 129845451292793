import { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'next/router';

import setCookie from '../../hooks/setCookie';
import getCookie from '../../hooks/getCookie';

import styles from './styles.module.scss';

const CookieBanner = (props) => {
  const { authStore } = props;
  const [isOpen, setOpen] = useState(!(authStore?.user?.id || getCookie('allowCookie')));

  const acceptHandler = () => {
    setCookie('allowCookie', true, 182)
    setOpen(false)
  }

  if(!isOpen) {
    return null;
  }

  return (
    <div className={styles.CookieBanner}>
      <div className="container">
        <div className={styles.CookieBanner_text}>
          <p>Продолжая пользоваться сайтом, Вы соглашаетесь с размещением cookie-файлов на Вашем устройстве на условиях, изложенных в <a href="/confidential">Политике конфиденциальности</a> и другие технологии (в т.ч. Яндекс.Метрика, а также Google Analytics, осуществляющая трансграничную передачу <a href='/rules'>персональных данных</a>)</p>
        </div>
        <button
          className={styles.CookieBanner_button}
          onClick={() => acceptHandler()}
        >
          Принять
        </button>
      </div>
    </div>
  );
};

export default inject(
  "authStore",
)(observer(withRouter(CookieBanner)));
