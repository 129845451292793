import ResourseStore from "./ResourseStore";
import {AxiosInstance} from "axios";
import {IBonusCard, IProfileBonusCard} from "../components/bonusPage/bonusCard/interface";
import {action, IAction, observable} from "mobx";

interface ITakeBonusById {
  bonus_id: number
}

export default class BonusStore extends ResourseStore {
  url: string;
  client: AxiosInstance;
  loadBonusesByUrl: ((string) => Promise<IProfileBonusCard[] | any>) & IAction;
  loadDetailBonus: ((id) => Promise<IProfileBonusCard>) & IAction;
  takeBonusByIdToUser: ((ITakeBonusById) => Promise<IBonusCard>) & IAction;
  takeBonusFromWidget: (() => Promise<{ data: string }>) & IAction;
  getLastAcceptedDonation: ((date: string) => Promise<any>);
  addBonusFeedback: ((data: {rating?: string, comment: string}, id: string) => Promise<any>);
  changeBonusFeedback: ((data: {rating?: string, comment: string}, id: string) => Promise<any>);
  getBonusFeedback: ((id) => Promise<any>);
  
  constructor(url: string, client: AxiosInstance) {
    super(url, client);
    this.url = url;
    this.client = client;
    this.records = observable([]);

    this.loadBonusesByUrl = action((url: string): Promise<IProfileBonusCard[]> => {
      return this.client.get(url).then(resp => {
        return resp.data
      })
    })

    this.loadDetailBonus = action((id: number): Promise<IProfileBonusCard> => {
      return this.client.get(`/api/bonuses/${id}/`).then(resp => resp.data)
    })

    this.takeBonusByIdToUser = action((data: ITakeBonusById): Promise<IBonusCard> => {
      return this.client.post(`/api/bonuses/get_bonus/`, data).then(resp => resp.data)
    })

    this.takeBonusFromWidget = action((): Promise<{ data: string }> => {
      return this.client.post(`/api/bonuses/link_checked/`).then(r => r)
    })

    this.getLastAcceptedDonation = action((date: string) => {
      return this.client.get(`/api/donations/?donate_at__gte=${date}&status=accepted,pre_accepted&page=1&page_size=1`).then(r => r)
    })

    this.addBonusFeedback = action((data: {rating?: string, comment: string}, id: string) => {
      return this.client.post(`/api/bonuses/${id}/feedback/`, data).then(r => r)
    })

    this.changeBonusFeedback = action((data: {rating?: string, comment: string}, id: string) => {
      return this.client.patch(`/api/bonuses/${id}/feedback/`, data).then(r => r)
    })

    this.getBonusFeedback = action((id: string) => {
      return this.client.get(`/api/bonuses/${id}/feedback/`).then(r => r.data)
    })
  }
}
