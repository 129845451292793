import React, { Component } from "react";
import styles from "./customCityPicker.module.scss";
import { inject, observer } from "mobx-react";
import AsyncSelect from "react-select/async";
import { Country, Region } from "../../entities";
import { CityItem } from "../bloodStations/cityItem";
import { get_city_label } from "../../utils/misc";
import ResourceStore from "../../stores/ResourseStore";
import SelectedCityStore from "../../stores/SelectedCityStore";

interface ICityOption {
  country?: Country;
  region?: Region;
  title: string;
  id: number;
  slug?: string;
}

class CityOption {
  value: number;
  label: JSX.Element | string;
  rawData: ICityOption;

  constructor(city: ICityOption) {
    this.value = city.id;
    this.label = (
      <CityItem
        address={get_city_label(city)}
        title={city.title}
        slug={city.slug}
        withIcon={false}
        noLink={true}
      />
    );
    this.rawData = city;
  }
}

@inject("citiesStore")
@inject("selectedCityStore")
@observer
export default class CustomCityPicker extends Component<any, any> {
  SearchStyle = {
    control: (styles, state) => ({
      ...styles,
      border: "1px solid #E5E9EE",
      cursor: "text",
      borderRadius: state.menuIsOpen ? "8px 8px 0 0" : "8px",
      boxShadow: state.menuIsOpen
        ? "0px 10px 20px 0px rgba(49,8,8,0.1)"
        : "none",
      borderColor: "transparent",
      backgroundColor: "transparent",
      "&:hover": {
        borderColor: "1px solid #37333F",
        boxShadow: "0px 10px 20px 0px rgba(49,8,8,0.1)",
      },
      zIndex: 1,
      maxHeight: "50px",
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: "13px",
    }),
    input: (styles) => ({
      ...styles,
      border: "none",
    }),
    indicatorSeparator: (styles) => ({
      display: "none",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "17px",
      letterSpacing: "-0.01em",
      color: "#C1C4CA",
      padding: "12px 12px 12px 3px",
      marginLeft: "0",
    }),
    menu: (styles, state) => {
      return {
        ...styles,
        zIndex: 3,
        marginTop: 0,
        borderRadius: "0 0 8px 8px",
        boxShadow: "0px 10px 20px 0px rgba(49,8,8,0.1)",
      };
    },
    menuList: (styles) => ({
      ...styles,
      paddingTop: "8px",
      paddingBottom: "12px",
    }),
    groupHeading: (styles) => {
      return {
        ...styles,
        color: "#C1C4CA",
        fontSize: "16px",
        fontWeight: "normal",
        lineHeight: "19px",
      };
    },
    option: (styles, state) => {
      let bacColor = "transparent";
      if (state.isFocused) bacColor = "#F6F7F8";
      if (state.isSelected) bacColor = "#F6F7F8";
      return {
        ...styles,
        backgroundColor: bacColor,
        whiteSpace: "break-spaces",
        "&:active": { backgroundColor: "#F73E3E" },
      };
    },
  };
  props: {
    citiesStore?: ResourceStore;
    selectedCityStore?: SelectedCityStore;
  };

  state: {
    city_options: ICityOption[];
    selected_city: ICityOption;
  };

  constructor(props) {
    super(props);
    this.state = {
      city_options: null,
      selected_city: null,
    };
  }

  clearInput = () => {
    this.setState({
      selected_city: null,
    });
  };

  getCityOptions = (event?: string) => {
    const filter = {};
    if (typeof location !== "undefined") {
      if (location?.hostname?.includes("tatar.")) {
        filter["region"] = 41;
      }
    }
    // filter['with_bs'] = true
    filter["s"] = event || "";
    const citiesArr = this.props.citiesStore.filter(filter).then((items) => {
      return items.map((item) => new CityOption(item.data as ICityOption));
    });
    return citiesArr;
  };

  onInputChange = (e) => {
    this.props.selectedCityStore.setCityId(e.rawData.id);
    if (e.rawData.id === 1) {
      this.props.selectedCityStore.setCity("Moscow");
    } else {
      this.props.selectedCityStore.setCity("Other");
    }
    this.setState({
      selected_city: { label: e.rawData.title, value: e.rawData.id },
      city_name: e.rawData.title,
    });
    if (typeof window !== "undefined") {
      window.location.reload();
    }
  };

  componentDidMount() {
    this.getCityOptions().then((defaultOptions) => {
      this.setState({ city_options: defaultOptions });
    });
    if (this.props.selectedCityStore.cityID) {
      this.props.citiesStore
        .loadOne(this.props.selectedCityStore.cityID)
        .then((city) => {
          this.setState({
            selected_city: { label: city.title, value: city.id },
            city_name: city.title,
          });
        })
        .catch(() => {
          this.setState({
            selected_city: { label: "Все регионы", value: 0 },
            city_name: "Все регионы",
          });
        });
    } else
      this.setState({
        selected_city: { label: "Все регионы", value: 0 },
        city_name: "Все регионы",
      });
  }

  render() {
    const {
      props,
      state,
      SearchStyle,
      clearInput,
      onInputChange,
      getCityOptions,
    } = this;
    const {} = props;
    const { city_options, selected_city } = state;
    return (
      <div className={styles.CustomCityPicker}>
        <AsyncSelect
          placeholder={"Выберите город"}
          cacheOptions={false}
          defaultOptions={city_options}
          loadOptions={getCityOptions}
          hideSelectedOptions={true}
          onChange={onInputChange}
          onMenuOpen={clearInput}
          styles={SearchStyle}
          defaultMenuIsOpen={true}
          autoFocus={true}
          loadingMessage={() => "Поиск..."}
          noOptionsMessage={
            city_options
              ? () => (
                  <span className={styles.CustomCityPicker_NotFounded}>
                    Если не нашли свой город,
                    <br />
                    напишите нам на <br />
                    <a href="mailto:support@donorsearch.org">
                      support@donorsearch.org
                    </a>
                  </span>
                )
              : () => <span>Поиск...</span>
          }
        />
      </div>
    );
  }
}
