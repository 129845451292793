import App from "next/app";
import "react-multi-carousel/lib/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/_library.scss";
import "../styles/react-datepicker.min.css";
import "../styles/staticPages.module.scss";
import "../components/GetBadgeComponent/gbcomp.css";
import React from "react";
import Header from "../components/header";
import Head from "next/head";
import initRootStore from "../stores";
import { observer, Provider } from "mobx-react";
import redirectTo from "../utils/redirectTo";
import Footer from "../components/footer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "semantic-ui-css/semantic.min.css";

import Router, { withRouter } from "next/router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import "cropperjs/dist/cropper.css";
import moment from "moment";
import Cookies from "cookies";
import RootStore from "../stores/RootStore";
import ErrorBoundary from "../components/errorBoundary";
import Error from "./_error";
import * as Sentry from "@sentry/node";
import "../styles/globals.css";
import SelectCityModal from "../components/selectCityModal";
import Spinner from "../components/spinner";
import { getCurrentMetaImg, getUserCityIdRoot, isNumber } from "../utils/misc";
import { BottomMenu, MobileRightMenu } from "../components/footer/footer";
import removeCookie from "../hooks/removeCookie";
import setCookie from "../hooks/setCookie";
import PwaInstallInstruction from "../components/pwaInstallInstruction";
import CookieBanner from "../components/cookieBanner";
import MainDonationForm from "../components/donationForm/mainDonationForm";

//Binding events.
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const setCitiesToCookie = (rootStore: RootStore) => {
  getUserCityIdRoot(
    rootStore.selectedCityStore,
    rootStore.authStore,
    rootStore.citiesStore
  ).then(() => {});
};

moment.locale("RU");

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    //enabled: process.env.NODE_ENV === 'production',
    enabled: true,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

@observer
class DonorApp extends App {
  props: any;
  rootStore: RootStore;
  state: {
    rightMenuIsOpened: boolean;
    hidden: boolean;
  };
  REF_CONTAINER: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    const isServer = typeof window === "undefined";
    this.REF_CONTAINER = React.createRef<HTMLDivElement>();
    this.state = { rightMenuIsOpened: false, hidden: false };
    this.rootStore = isServer
      ? props.initialState
      : initRootStore(props.initialState);
    Router.events.off("routeChangeComplete", () =>
      setCitiesToCookie(this.rootStore)
    );
    Router.events.on("routeChangeComplete", () =>
      setCitiesToCookie(this.rootStore)
    );
  }

  static async getInitialProps(appContext) {
    let pageProps = {};
    let err;
    if (appContext.Component.getInitialProps) {
      try {
        pageProps = await appContext.Component.getInitialProps(appContext.ctx);
      } catch (error) {
        err = error;
        Sentry.captureException(error);
      }
    }
    const rootStore = initRootStore({} as RootStore);
    appContext.ctx.rootStore = rootStore;

    if (appContext.ctx.req) {
      const cookies = new Cookies(appContext.ctx.req, appContext.ctx.res);
      let token = cookies.get("token");

      const passportSession = cookies.get("session");
      if (passportSession) {
        const serializedCookie = Buffer.from(
          passportSession,
          "base64"
        ).toString();
        const parseSession = JSON.parse(serializedCookie);
        const user =
          parseSession["passport"] && parseSession["passport"]["user"]
            ? parseSession["passport"]["user"]
            : null;
        if (user && user.error) {
          rootStore.uiStore.setSocialError(user.error);
          cookies.set("session");
        } else if (user) {
          token = user;
          const today = new Date();
          const tomorrow = new Date(today);
          tomorrow.setDate(tomorrow.getDate() + 7);
          cookies.set("token", user, {
            expires: tomorrow,
            httpOnly: false,
            domain: ".donorsearch.org",
          });
          cookies.set("token", user, {
            expires: tomorrow,
            httpOnly: false,
            domain: "unity.redcross.ru",
          });
          cookies.set("session");
        }
      }
      rootStore.tokenStore.set(token, true);
    }

    if (
      appContext.ctx.pathname.startsWith("/newprofile") &&
      !rootStore.tokenStore.get()
    ) {
      redirectTo("/login", { res: appContext.ctx.res, status: 302 });
    }

    if (
      appContext.ctx.pathname.startsWith("/profile") &&
      !rootStore.tokenStore.get() && 
      (appContext.ctx.pathname !== '/profile/donations/[id]')
    ) {
      if (appContext &&
        appContext.ctx &&
        appContext.ctx.req && appContext.ctx.pathname !== '/profile/donations/[id]/edit') {
        const cookies = new Cookies(appContext.ctx.req, appContext.ctx.res);
        cookies.set("next", appContext.ctx.pathname, {
          httpOnly: false,
        });
      }
      redirectTo("/login", { res: appContext.ctx.res, status: 302 });
    }

    if (rootStore.tokenStore.get()) {
      removeCookie("ReferralCode")
      if(typeof sessionStorage !== "undefined") sessionStorage?.removeItem("ReferralCode")
    }

    if (
      appContext.ctx.pathname.startsWith("/login") &&
      rootStore.tokenStore.get()
    ) {
      redirectTo("/profile", { res: appContext.ctx.res, status: 302 });
    }

    if (
      appContext.ctx.pathname.startsWith("/pre_sign_up") &&
      rootStore.tokenStore.get()
    ) {
      redirectTo("/profile", { res: appContext.ctx.res, status: 302 });
    }

    if (
      appContext.ctx.pathname.startsWith("/sign_up") &&
      rootStore.tokenStore.get()
    ) {
      redirectTo("/profile", { res: appContext.ctx.res, status: 302 });
    }
  
    if (
      appContext.ctx.pathname === "/profile/settings"
    ) {
      redirectTo("/profile/settings/personal", { res: appContext.ctx.res, status: 302 });
    }

    if (
      appContext.ctx.pathname.startsWith("/vmesteokean") && !appContext.ctx.pathname.includes('donors') &&
      rootStore.tokenStore.get()
    ) {
      redirectTo("/vmesteokean/donors", { res: appContext.ctx.res, status: 302 });
    }

    if (
      appContext.ctx.pathname.startsWith("/vmesteokean") && appContext.ctx.pathname.includes('donors') &&
      !rootStore.tokenStore.get() && !appContext.ctx.query?.userId
    ) {
      redirectTo("/vmesteokean", { res: appContext.ctx.res, status: 302 });
    }


    let noindex = false;

    if (
      appContext.ctx.pathname.startsWith("/profile") ||
      appContext.ctx.pathname.startsWith("/login") ||
      appContext.ctx.pathname.startsWith("/pre_sign_up") ||
      appContext.ctx.pathname.startsWith("/sign_up") ||
      appContext.ctx.pathname.startsWith("/reset")
    ) {
      noindex = true;
    }

    await rootStore.authStore.fetchCurrentUser();
    if (appContext.ctx.req) {
      const cookies = new Cookies(appContext.ctx.req, appContext.ctx.res);
      let CookieCity = cookies.get("City");
      let CookieCity_id = cookies.get("City_id");

      const header_city = appContext.ctx.req.headers["x-city"];
      const hostURL = appContext.ctx.req.headers.host;

      if (header_city) {
        rootStore.selectedCityStore.setHeaderCity(header_city);
      }

      let localCity;
      let localCityID;

      if (CookieCity) {
        localCity = CookieCity;
      } else {
        localCity =
          rootStore.authStore?.user?.city?.id === 1 ? "Moscow" : "Other";
        if (hostURL?.includes("moscow.")) {
          localCity = "Moscow";
        }
        if (hostURL?.includes("tatar.")) {
          localCity = "Other";
        }
      }

      if (CookieCity_id) {
        localCityID = CookieCity_id;
      } else {
        localCityID = rootStore.authStore?.user?.city?.id;
        if (hostURL.includes("moscow.")) {
          localCityID = 1;
        }
        if (hostURL.includes("tatar.")) {
          localCityID = 50;
        }
      }

      if (rootStore.selectedCityStore.city != localCity) {
        rootStore.selectedCityStore.setCity(localCity);
      }

      if (rootStore.selectedCityStore.cityID != localCity) {
        await rootStore.selectedCityStore.setCityId(localCityID);
      }
    }

    return {
      initialState: rootStore,
      pageProps: pageProps,
      err: err,
      noindex: noindex,
    };
  }

  setRightMenuIsOpened = (value: boolean) => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      if (typeof document !== "undefined") {
        document.body.style.overflow = value ? "hidden" : "initial";
        document.getElementById("__next").style.overflow = value
          ? "hidden"
          : "initial";
        document.getElementById("maino").style.overflow = value
          ? "hidden"
          : "initial";
      }
    }
    this.setState({ rightMenuIsOpened: value });
  };

  setHidden = (value: boolean) => {
    this.setState({ hidden: value });
  };

  storePathValues = () => {
    const storage = globalThis?.sessionStorage;
    if (!storage) return;
    const prevPath = storage.getItem("currentPath");
    if (prevPath === globalThis.location.pathname) return;
    storage.setItem("prevPath", prevPath);
    storage.setItem("currentPath", globalThis.location.pathname);
  };

  componentDidMount(): void {
    const isToken = this.props.router.asPath.split("&")[1] === "token";
    let token = this.rootStore.tokenStore.get();
    if (isToken) {
      this.props.router.replace(this.props.router.asPath.split("&")[0]);
      removeCookie("token", ".donorsearch.org");
      this.rootStore.tokenStore.remove();
    }
    if (this.props.router.pathname === "/login" && !isToken && token) {
      this.props.router.push("/profile");
    }
  }

  componentDidUpdate() {
    this.rootStore.authStore.fetchCurrentUser();
    // scroll to top after linking
    const nodeContainer = this.REF_CONTAINER.current;
    nodeContainer.scrollIntoView({ behavior: "smooth" });
    this.storePathValues();
  }

  render() {
    const { Component, pageProps, err, noindex } = this.props;
    let showFooter = true;
    let showMSK = true;
    if (err) {
      return <Error />;
    }
    if (typeof window !== "undefined") {
      const path = window.location.pathname;
      const match = path.match("/bloodstations/(.*)");
      if (match) showFooter = isNumber(match[1].slice(0, match[1].length - 1));
      if (window.location.pathname !== "/") {
        showMSK = false;
      }
    }
    
    return (
      <Provider {...this.rootStore}>
        <div
          ref={this.REF_CONTAINER}
          style={{ height: "100vh", display: "flex", flexDirection: "column" }}
          id={"maino"}
        >
          <Head>
            <script
              dangerouslySetInnerHTML={{
                __html: `if('serviceWorker' in navigator){window.addEventListener('load',()=>{navigator.serviceWorker.register('./service-worker.js').then((registration)=>{console.log('Service worker registered successfully',registration)}).catch((error)=>{console.error('Something went wrong',error)})})}`,
              }}
            />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
              href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
              rel="prefetch"
              as="font"
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicon-32x32.svg"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicon-16x16.png"
            />
            <link rel="manifest" href="/manifest.json" />
            <link
              rel="mask-icon"
              href="/safari-pinned-tab.svg"
              color="#5bbad5"
            />
            {/* <meta name="apple-itunes-app" content="app-id=1405587730" /> */}
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ef0000" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover, minimal-ui"
            />

            <meta property="og:title" content="Мотивируем сдавать кровь" />
            <meta
              property="og:description"
              content="Крупнейшее сообщество доноров. Поддерживаем доноров с первой донации!"
            />
            <meta
              name="description"
              content="Крупнейшее сообщество доноров. Поддерживаем доноров с первой донации!"
            />
            <link rel="image_src" href={`/static/images/newMetaImg.png`} />
            <meta property="og:image:height" content={"315"} />
            <meta property="og:image:width" content={"600"} />
            <meta
              property="twitter:image"
              content={`/static/images/newMetaImg.png`}
            />
            <meta property="twitter:card" content="summary_large_image" />
            <meta
              property="vk:image"
              content={`/static/images/newMetaImg.png`}
              key="mainImage"
            />
            <meta
              property="og:image"
              content={`/static/images/newMetaImg.png`}
              key="mainImage"
            />
            <meta
              name="keywords"
              content="донорство крови, сдать кровь, donorsearch"
            />
            <meta property="og:site_name" content="DonorSearch.org" />
            <meta property="og:type" content="website" />
            <meta property="og:app_id" content="1760058084257879" />
            <meta property="fb:app_id" content="1760058084257879" />

            {noindex && <meta name="robots" content="noindex, nofollow" />}

            <link
              rel="prefetch"
              href="/css/material-design-iconic-font.min.css"
              as="style"
            />
            <title>{this.rootStore.uiStore.title}</title>
            {process.env.NEXT_PUBLIC_GOOGLE_TAG && (
              <script
                defer
                dangerouslySetInnerHTML={{
                  __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG}');`,
                }}
              />
            )}
            {process.env.NEXT_PUBLIC_GOOGLE_TAG && (
              <noscript
                dangerouslySetInnerHTML={{
                  __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG}"
                       height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                }}
              />
            )}

            {process.env.NEXT_PUBLIC_YANDEX_KEY && (
              <script
                defer
                dangerouslySetInnerHTML={{
                  __html: `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)}; m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym"); ym(${process.env.NEXT_PUBLIC_YANDEX_KEY}, "init", { clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true, trackHash:true, ecommerce:"dataLayer" }); ym(${process.env.NEXT_PUBLIC_YANDEX_KEY_SECOND}, "init", { clickmap:true, trackLinks:true, accurateTrackBounce:true });`,
                }}
              />
            )}
            {process.env.NEXT_PUBLIC_YANDEX_KEY_SECOND && (
              <noscript
              dangerouslySetInnerHTML={{
                __html: `<div><img src="https://mc.yandex.ru/watch/${process.env.NEXT_PUBLIC_YANDEX_KEY_SECOND}" style="position:absolute; left:-9999px;" alt="" /></div>`,
              }}
            />
            )}
            {process.env.NEXT_PUBLIC_MAIL_KEY && (
              <>
                <script
                  defer
                  dangerouslySetInnerHTML={{
                    __html: `var _tmr = window._tmr || (window._tmr = []);
                    _tmr.push({id: "${process.env.NEXT_PUBLIC_MAIL_KEY}", type: "pageView", start: (new Date()).getTime()});
                    (function (d, w, id) {
                      if (d.getElementById(id)) return;
                      var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
                      ts.src = "https://top-fwz1.mail.ru/js/code.js";
                      var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
                      if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
                    })(document, window, "tmr-code");`,
                  }}
                />
                <noscript
                  dangerouslySetInnerHTML={{
                    __html: `<div><img src="https://top-fwz1.mail.ru/counter?id=${process.env.NEXT_PUBLIC_MAIL_KEY};js=na" style="position:absolute;left:-9999px;" alt="Top.Mail.Ru" /></div>`,
                  }}
                />
              </>
            )}
            <script
              defer
              dangerouslySetInnerHTML={{
                __html: `(function(d,w,t,u,o,s,v) { w[o] = w[o] || function() { (w[o].q = w[o].q || []).push(arguments) };s = d.createElement("script");s.async = 1; s.src = u;v = d.getElementsByTagName(t)[0]; v.parentNode.insertBefore(s, v); })(document,window,"script","//image.sendsay.ru/js/target/tracking_v2.min.js","sndsy"); sndsy("init", { fid: "donorsearch", v: "1.0" }); sndsy("send", {});`
              }}
            />
            <script src="https://widget.cloudpayments.ru/bundles/cloudpayments.js"></script>
          </Head>
          <Header {...pageProps} toggledMSK={showMSK} />
          <ErrorBoundary>
            <Spinner toggled={this.rootStore.spinnerStore.stateSpinner} onAll />
            <Component {...pageProps} err={err} />
            <SelectCityModal />
          </ErrorBoundary>
          <MainDonationForm />
          {true && <Footer opened={this.state.rightMenuIsOpened} />}
          <MobileRightMenu
            opened={this.state.rightMenuIsOpened}
            setOpened={this.setRightMenuIsOpened}
          />
          <BottomMenu
            setOpened={this.setRightMenuIsOpened}
            opened={this.state.rightMenuIsOpened}
          />
          <PwaInstallInstruction />
          <CookieBanner />
        </div>
      </Provider>
    );
  }
}

export default withRouter(DonorApp);
