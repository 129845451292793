import Cookies from "js-cookie";

const setCookie = (cookiename, usrin, expires = 365) => {
  Cookies.set(cookiename, usrin, {
    expires: expires, //day
    secure: true,
    sameSite: "strict",
    path: "/",
    // domain: ".donorsearch.org",
  });
};

export default setCookie;
