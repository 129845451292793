import ResourseStore from "./ResourseStore";
import {AxiosInstance} from "axios";
import {action} from "mobx";

export default class OkeanVmesteStore extends ResourseStore {
  url: string;
  client: AxiosInstance;

  getRandomUsers: (() => any);
  getUserById: ((id: string | string[]) => any);
  
  constructor(url: string, client: AxiosInstance) {
    super(url, client);
    this.url = url;
    this.client = client;

    this.getRandomUsers = action(() => {
      return this.client.get(`${this.url}/`).then(resp => resp.data)
    })

    this.getUserById = action((id: string | string[]) => {
      return this.client.get(`${this.url}/${id}/`).then(resp => resp.data)
    })
  }
}
