import ResourseStore from "./ResourseStore";
import {AxiosInstance} from "axios";
import {action, observable} from "mobx";

export default class SouvenirStore extends ResourseStore {
  url: string;
  client: AxiosInstance;

  getGiftStatus: (() => any);
  getGifts: (() => any);
  
  constructor(url: string, client: AxiosInstance) {
    super(url, client);
    this.url = url;
    this.client = client;
    this.records = observable([]); 

    this.getGiftStatus = action(() => {
      return this.client.get(`/api/profile/gifts-status/`).then(resp => resp.data)
    })

    this.getGifts = action(() => {
      return this.client.post(`/api/profile/request-gifts/`).then(resp => resp.data)
    })
  }
}
